import React, { Fragment, useMemo } from 'react';
import { useCurrentRoute } from 'react-navi';

import { FloatingRequestAccessButton } from '../../../components/FloatingRequestAccessButton';
import { Helmet } from '../../../components/Helmet';
import { withTrustShareNavigationBar } from '../../../components/NavigationBar';
import { useMeasurePaint, useMeasureRender, useMeasureRoute } from '../../../lib/measurement';
import NotificationCenterHeader from '../home/components/NotificationCenterHeader';
import * as Styles from './Notifications.styles';
import NotificationCard from '../home/components/NotificationCard';
import { useAuthService, useTrustshareNotifications } from '../../../lib/state';
import CustomizeBanner from '../home/components/CustomizeTrustshareBanner';

const NOTIFICATION_CARD = {
  id: 'notification-card',
  accessorFn: (data) => data.id,
  cell: ({ row }) => (
    <NotificationCard
      type={row.original.type}
      title={row.original.title}
      dateTime={row.original._metadata.createdDate}
      description={row.original.description?.split('\n').map((p) => (
        <Fragment key={p}>
          {p}
          <br />
        </Fragment>
      ))}
      links={row.original.links}
    />
  ),
};

const Notifications = withTrustShareNavigationBar(() => {
  const {
    data: { span },
  } = useCurrentRoute();
  span.setAttribute('page-name', 'notifications');
  useMeasureRoute(span);
  const { data: trustshareNotifications } = useTrustshareNotifications();
  const { authenticatedUser } = useAuthService();

  const sortedTrustshareNotifications = useMemo(() => {
    return [...trustshareNotifications].sort((a, b) => {
      return new Date(b._metadata.createdDate) - new Date(a._metadata.createdDate);
    });
  }, [trustshareNotifications]);

  const DEFAULT_PROPS = {
    columns: [NOTIFICATION_CARD],
    data: sortedTrustshareNotifications,
    getRowId: (row) => row.id,
    pagination: true,
    selection: false,
    search: false,
  };

  if (!trustshareNotifications.length) {
    return null;
  }

  const tree = (
    <>
      <Helmet pageTitle="Notifications" />
      <Styles.NotificationsPageContainer>
        <Styles.NotificationCenterContainer>
          <NotificationCenterHeader />
          <Styles.NotificationTable
            {...DEFAULT_PROPS}
            variant="primary"
            pageSize={5}
          />
        </Styles.NotificationCenterContainer>
      </Styles.NotificationsPageContainer>
      {authenticatedUser?.id && <CustomizeBanner />}
      <FloatingRequestAccessButton />
    </>
  );

  useMeasureRender(span);
  useMeasurePaint(span);

  return tree;
});

export default Notifications;
