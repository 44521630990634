import React from 'react';
import { css, Global, ThemeProvider } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

// Locale modules
import { hexOpacity } from '../lib/utils';

// Assets
import trustShareFooter from '../assets/ts-footer.jpg';

const RADIUS_BASE_UNIT = 2;

const defaultTheme = {
  metaTitle: 'TrustShare',
  palette: {
    arctic: '#DAEAEC',
    ariel: '#73A5A8',
    bluegrey: '#C6D5D6',
    bone: '#F5F9FA',
    chalkboard: '#515151',
    forest: '#73A5A8',
    granite: '#687D86',
    jade: '#17BF92',
    navy: '#283562',
    shadow: '#2B2D2D',
    white: '#FFFFFF',
    pebble: '#ABD4D8',
    royal: '#465AAA',
    slate: '#D9D9D9',
    peacock: '#01818a',
    pimento: '#DD4C0E',
    stone: '#B5B5B5',
    silver: '#E6E6E6',
    whitegray: '#C8D7D9',
    elephant: '#7f8585',
    volare: '#002767',
    antigua: '#286B6F',
    moonlight: '#6059AF',
  },
  spacing: {
    0: '0rem',
    xs: '0.26rem',
    sm: '0.37rem',
    md: '0.75rem',
    lg: '1.5rem',
    xl: '1.875rem',
    '2xl': '6rem',
  },
  radius: {
    sm: `${RADIUS_BASE_UNIT}px`,
    md: `${2 * RADIUS_BASE_UNIT}px`,
    lg: `${3 * RADIUS_BASE_UNIT}px`,
    xl: `${4 * RADIUS_BASE_UNIT}px`,
    '2xl': `${5 * RADIUS_BASE_UNIT}px`,
  },
};

export { defaultTheme as theme };

defaultTheme.components = {
  text: {
    primary: defaultTheme.palette.chalkboard,
    secondary: defaultTheme.palette.stone,
    size: {
      h1: '2.25rem',
      h2: '1.75rem',
      h2stronger: '1.25rem',
      h3: '1.375rem',
      h3title: '1.5rem',
      h4: '1.125rem',
      body: '1rem',
      strong: '1rem',
      medium: '1rem',
      small: '0.875rem',
      smaller: '0.75rem',
      label: '0.875rem',
      byline: '0.75rem',
      labelbyline: '0.75rem',
      tagline: '2rem',
      h1stronger: '2.5rem',
      labelsmallerthin: '0.625rem',
    },
    weight: {
      h1: 300,
      h2: 400,
      h2stronger: 600,
      h3: 600,
      h3quiet: 300,
      h3title: 500,
      h4: 600,
      h4quiet: 500,
      h4thin: 400,
      body: 300,
      medium: 400,
      strong: 600,
      small: 400,
      smallstrong: 500,
      smallthin: 300,
      smaller: 400,
      smallerstrong: 500,
      smallerthin: 300,
      labelstrong: 500,
      byline: 600,
      labelbyline: 400,
    },
    lineHeight: {
      h1: 1.44,
      h2: 1.29,
      h3: 'normal',
      h3quiet: 1.64,
      h4: 1.44,
      body: 1.63,
      strong: 1.38,
      small: 1.43,
      smaller: 1.5,
      byline: 1.5,
      h2low: 1.25,
      stronger: 1.6,
      labelsmallerthin: 1,
    },
  },
  iconbutton: { highlight: hexOpacity(defaultTheme.palette.shadow, 0.1) },
  sidebar: {
    activeBorderColor: hexOpacity(defaultTheme.palette.peacock, 0.5),
    expandedWidth: '198px',
    highlightColor: defaultTheme.palette.ariel,
    itemAlignment: 'center',
    itemHeight: '52px',
    linearGradientColor: hexOpacity(defaultTheme.palette.peacock, 0.2),
    borderColor: hexOpacity(defaultTheme.palette.white, 0.7),
  },
  modal: { title: defaultTheme.palette.granite },
  button: {
    text: { primary: defaultTheme.palette.bone, secondary: defaultTheme.palette.navy },
    background: { primary: defaultTheme.palette.navy, secondary: defaultTheme.palette.white },
    states: {
      primary: {
        hover: { boxShadow: `0px 2px 4px 2px ${hexOpacity(defaultTheme.palette.stone, 0.8)}` },
        active: { boxShadow: `inset 0px -2px 4px 1px ${defaultTheme.palette.navy}` },
      },
    },
    weight: { default: 500 },
  },
  tooltip: {
    background: { primary: defaultTheme.palette.navy },
    text: { primary: defaultTheme.palette.white },
  },
  header: {
    avatar: {
      background: `
        linear-gradient(130deg, ${defaultTheme.palette.pebble} 0%,
        ${hexOpacity(defaultTheme.palette.arctic, 0.6)} 100%);
      `,
      textColor: defaultTheme.palette.chalkboard,
    },
    navigation: {
      backgroundColor: defaultTheme.palette.white,
      textColor: defaultTheme.palette.navy,
    },
    trustShareLabel: { textColor: defaultTheme.palette.chalkboard },
    connectionIndicator: { backgroundColor: defaultTheme.palette.forest },
    primaryCTAButton: {
      background: `
          linear-gradient(270deg, ${defaultTheme.palette.pebble} 0%,
          ${defaultTheme.palette.arctic} 100%);
      `,
      textColor: defaultTheme.palette.chalkboard,
    },
  },
  footer: {
    background: `no-repeat url(${trustShareFooter});`,
    boxShadow: `inset 0px 1px 8px 4px ${hexOpacity(defaultTheme.palette.stone, 0.25)};`,
  },
};

export const Provider = ({ theme = defaultTheme, children }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={() => css`
        ${emotionNormalize}
        *, *:before, *:after {
          box-sizing: border-box;
        }
        body {
          font-family: 'Hanken Grotesk', system-ui;
          font-weight: 400;
          overflow-y: scroll;
          -webkit-font-smoothing: antialiased;
        }
        a {
          color: currentColor;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        blockquote,
        dl,
        dd,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        hr,
        figure,
        ol,
        p,
        pre,
        ul {
          margin: 0;
          padding: 0;
        }
      `}
    />
    {children}
  </ThemeProvider>
);
