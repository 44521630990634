import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Badge, Heading, Toast } from '@kintent/glide';
import { useContainer } from 'unstated-next';

import DashboardCertificationSectionItemCard from './DashboardCertificationItemCard';
import HomePageContainer from './HomePageContainer';
import { Flex } from '../../../../components/Flex';
import {
  useAuthService,
  useFeatureFlag,
  useProgramContent,
  useSegment,
  useTeamCertificationList,
} from '../../../../lib/state';
import { getDefaultCertificationDescription, getTeamCertificationsWithChildren } from '../../../../lib/utils';
import { DOCUMENT_ACCESS_LEVEL } from '../../../../lib/constants';
import { FEATURE_FLAG } from '../../../../lib/featureFlags';
import { SegmentProvider } from '../../../../components/SegmentProvider';

// Styled components
const CertificiationLayoutContainer = styled(HomePageContainer)`
  margin-top: 72px;
`;

const CertificationListGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`;

const StyledBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.components.header.primaryCTAButton.background};
`;

const getPublicFacingDescription = (publicFacingStandards, shortName, subtype) => {
  if (shortName) {
    return publicFacingStandards[`${shortName}${subtype ?? ''}`];
  }
  return false;
};

function DashboardCertificationSection() {
  const { currentTeam } = useAuthService();
  const [rawCertifications] = useTeamCertificationList(currentTeam.id);
  const [programContent] = useProgramContent();
  const isPortalSegmentAware = useFeatureFlag(FEATURE_FLAG.SEGMENT_AWARE_TS_PORTAL_V1);
  const { segmentId } = useContainer(SegmentProvider);
  const { data: segment } = useSegment(segmentId);

  const [shouldShowDownloadErrorToast, setShouldShowDownloadErrorToast] = useState(false);
  const { publicFacingDescriptions: { standards: publicFacingStandards = {} } = {} } = programContent || {};

  const selectedSegment = useMemo(() => (isPortalSegmentAware ? segment : null), [isPortalSegmentAware, segment]);

  const certifications = getTeamCertificationsWithChildren(rawCertifications);

  const visibleCertifications = useMemo(() => {
    if (selectedSegment) {
      return certifications
        .map((item) => {
          const children = item.children.filter((child) =>
            selectedSegment.content.data.certifications.includes(child.id)
          );
          return {
            ...item,
            children,
          };
        })
        .filter((item) => {
          return (
            item.children.filter((child) => {
              return [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(
                child.trustShareAccessLevel
              );
            })?.length > 0
          );
        });
    }
    return certifications.filter((item) => {
      return (
        item.children.filter((child) => {
          return [DOCUMENT_ACCESS_LEVEL.PUBLIC, DOCUMENT_ACCESS_LEVEL.PRIVATE].includes(child.trustShareAccessLevel);
        })?.length > 0
      );
    });
  });

  // Return early when there are no certifications for display
  if (visibleCertifications.length === 0) return null;

  return (
    <CertificiationLayoutContainer as="section">
      <Flex
        direction="column"
        gap="16px"
      >
        <Flex
          gap="16px"
          alignItems="center"
        >
          <StyledBadge variant="primary">{visibleCertifications.length}</StyledBadge>
          <Heading level="3">Certifications</Heading>
        </Flex>
        <CertificationListGrid>
          {visibleCertifications?.map((item) => {
            // this sets the firstChild as the parentCertification if the parent is hidden
            const parentCertification =
              item.children.find((child) => child.parentId === null) ?? item.children[0] ?? {};
            return (
              <DashboardCertificationSectionItemCard
                key={item.id}
                accessLevel={parentCertification?.trustShareAccessLevel}
                certificationUrl={parentCertification?.certificationUrl}
                documentCount={item.children.length}
                certificationId={parentCertification?.id}
                description={
                  getPublicFacingDescription(
                    publicFacingStandards,
                    item.certification?.shortName,
                    item.children[0]?.subtype ?? item.subtype
                  ) ??
                  getDefaultCertificationDescription(
                    currentTeam?.name,
                    item.certification.shortName,
                    item.children[0]?.status ?? item.status,
                    item.children[0]?.subtype ?? item.subtype
                  )
                }
                filename={item.children[0]?.filename ?? item.filename}
                shortName={item.certification.shortName}
                subtype={item.children[0]?.subtype ?? item.subtype}
                setShouldShowDownloadErrorToast={setShouldShowDownloadErrorToast}
              />
            );
          })}
        </CertificationListGrid>
      </Flex>
      <Toast
        variant="negative"
        label="Error"
        description="We couldn't generate the download file, please contact support."
        open={shouldShowDownloadErrorToast}
        duration={1000}
        onOpenChange={() => setShouldShowDownloadErrorToast(false)}
      />
    </CertificiationLayoutContainer>
  );
}

export default DashboardCertificationSection;
