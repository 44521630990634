// Package modules
import React from 'react';
import { compose, map, mount, redirect, route, withData, withView } from 'navi';
import opentelemetry from '@opentelemetry/api';
import { configCatClient, FEATURE_FLAG } from 'lib/featureFlags';
import PolicyView from './app/trust-share/policies/components/PolicyView';
import CreateAccount from './app/create-account/CreateAccount';
import Home from './app/trust-share/home/Home';

// Local modules
import { AppLayout, FullPageLayout } from './components/AppLayout';
import { DocumentListV2 } from './app/trust-share/documents/DocumentListV2';
import CertificationsFeatureSwitcher from './app/trust-share/certifications/CertificationsFeatureSwitcher';
import { Subprocessors } from './app/trust-share/subprocessors/Subprocessors';
import { RequestAccess } from './app/trust-share/request-access/RequestAccess';
import ControlDetail from './app/trust-share/controls/ControlDetail';
import PolicyDetail from './app/trust-share/policies/PolicyDetail';

// User routes
import { Login } from './app/user/login/Login';
import { Join } from './app/user/join/Join';
import { ForgotPassword } from './app/user/forgot-password/ForgotPassword';
import { AccountSummary } from './app/trust-share/account-summary/AccountSummary';
import OnBoardingJourney from './components/OnBoardingJourney';

// Utils & Api
import { api } from './lib/api';

import { CERTIFICATIONS_INFO, DOCUMENTS_INFO, SUBPROCESSORS_INFO, USER_TYPE } from './lib/constants';
import { AuthService } from './lib/authService';

import ApiError, { UNAUTHORIZED } from './lib/errors/ApiError';
import ConnectTrustshare from './app/create-account/ConnectTrustshare';
import { parseJwt } from './lib/utils';
import CertificationsV2 from './app/trust-share/certifications/CertificationsV2';
import CertificationStandardDetail from './app/trust-share/certifications/CertificationStandardDetail';
import Notifications from './app/trust-share/notifications/Notifications';
import { Faq } from './app/trust-share/faq/Faq';

const tracer = opentelemetry.trace.getTracer('routing-tracer');

const authService = AuthService.getAuthServiceInstance();

async function verifyTeamContext(publicTeamId) {
  if (!publicTeamId) {
    await authService.publicLogin();
  }
}

// Since we can't use hooks here, we're only checking once if we need to show enterprise grade TS since the layout is different
async function verifyEnterpriseGrade(publicTeamId) {
  const flagUser = {
    custom: {
      teamId: publicTeamId,
    },
  };

  const getFeatureFlagValue = () => configCatClient.getValueAsync(FEATURE_FLAG.ENTERPRISE_GRADE_TS, false, flagUser);
  return getFeatureFlagValue();
}

async function getInvitationData(req) {
  const invitationId = req.params.invitation;
  try {
    const invitation = await api.invitations.get(invitationId);
    return { invitation, token: invitationId };
  } catch (e) {
    return { invitationFetchError: e };
  }
}

async function handleSSOResponse(params) {
  /**
   * This error condition strictly happens on a redirect i.e ?error=true
   * For the client to handle this error gracefully, we build the error object on the client-side
   */
  const publicTeamId = authService.getPublicTeamId();

  if (params.error) {
    const ssoError = new Error();
    ssoError.error = UNAUTHORIZED;
    ssoError.message = UNAUTHORIZED;
    throw new ApiError(ssoError);
  }
  if (params.token && params.provider) {
    const { selectedTeamId, user, token } = await api.auth.loginWithSSO(
      params.provider,
      params.token,
      params.invitation,
      USER_TYPE.TEMPORARY_GUEST
    );

    const parsedToken = parseJwt(token);

    // If teamId is absent in the token, the user is logging into a TS for which the user has not connected before.
    if (!parsedToken.teamId) {
      window.location.href = `/connect?token=${token}`;
      return;
    }

    /**
     * Due to multi-team support, the client needs to check if the user is logging into their last selectedTeamId.
     * If a user is not logging into a TS that matches their last selectedTeamId, the client invokes the select team API
     * endpoint to verify if they have access to this TS.
     */

    /**
     * Select team should only be called when an invitation token is not present in the url params
     * If an invitation is present, it is handled by the joinAndSwitchTeam handler below
     */
    if (selectedTeamId !== publicTeamId && !params.invitation) {
      const { token: updatedToken, user: updatedUser } = await api.auth.selectTeam(publicTeamId, token);
      authService.updateAuth(updatedToken, updatedUser);
    } else {
      authService.updateAuth(token, user);
    }

    /**
     * When an invitation is present in the url, we should invoke joinAndSwitch team.
     * joinAndSwitchTeam also ensures that selectTeam is called when/if required
     */
    if (params.invitation) {
      await authService.joinAndSwitchTeam(user.id, params.invitation, token);
    }
  }
}

export const routes = mount({
  '/': redirect('/home'),
  '/login': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);

    // If user is already signed in, redirect to the URL they were going to before they got to /login, or otherwise to /home.
    const isLoggedIn = await AuthService.getAuthServiceInstance().isLoggedIn();
    if (isLoggedIn) {
      return redirect('/home');
    }

    let ssoError = null;
    try {
      await handleSSOResponse(req.params);
    } catch (error) {
      ssoError = error;
    }

    return compose(
      withData(() => {
        const span = tracer.startSpan('login-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({ view: <Login error={ssoError} /> }),
      })
    );
  }),
  '/connect': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);

    // If user is already signed in, redirect to the URL they were going to before they got to /login, or otherwise to /home.
    const isLoggedIn = await AuthService.getAuthServiceInstance().isLoggedIn();
    if (isLoggedIn) {
      return redirect('/home');
    }

    return compose(
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({ view: <ConnectTrustshare /> }),
      })
    );
  }),
  '/join': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);

    // If a user is already signed in, redirect to the URL they were going to before they got to /login, or otherwise to /home.
    const isLoggedIn = await authService.isLoggedIn();

    if (isLoggedIn) {
      return redirect('/home');
    }

    let ssoError = false;
    try {
      await handleSSOResponse(req.params);
    } catch (e) {
      ssoError = true;
    }

    const { invitation, token } = await getInvitationData(req);

    if (invitation?.userExists) {
      return redirect(`/login?invitation=${token}`);
    }

    return compose(
      withData(() => {
        const span = tracer.startSpan('join-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({
          getData: () => getInvitationData(req),
          view: <Join showError={ssoError} />,
        }),
      })
    );
  }),
  '/password-reset': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);
    const { resetRequest } = req.params;
    return compose(
      withData(() => {
        const span = tracer.startSpan('password-reset-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({ '/': route({ view: <ForgotPassword resetToken={resetRequest} /> }) })
    );
  }),
  '/request-access': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);

    // If user is already signed in, redirect to the URL they were going to before they got to /login, or otherwise to /home
    const isLoggedIn = await AuthService.getAuthServiceInstance().isLoggedIn();
    if (isLoggedIn) {
      return redirect('/home');
    }

    let ssoError = false;
    try {
      await handleSSOResponse(req.params);
    } catch (e) {
      ssoError = true;
    }

    return compose(
      withData(() => {
        const span = tracer.startSpan('request-access-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({ '/': route({ view: <RequestAccess showError={ssoError} /> }) })
    );
  }),
  '/create-account': map(async (req, context) => {
    await verifyTeamContext(context.publicTeamId);

    // If user is already signed in, redirect to the URL they were going to before they got to /login, or otherwise to /home
    const isLoggedIn = await AuthService.getAuthServiceInstance().isLoggedIn();
    if (isLoggedIn) {
      return redirect('/home');
    }

    return compose(
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({ '/': route({ view: <CreateAccount /> }) })
    );
  }),
  '/homev2': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    const isEnterpriseGradeTSEnabled = await verifyEnterpriseGrade();
    if (isEnterpriseGradeTSEnabled) {
      return compose(withView(<div>Enterprise Grade</div>));
    }
    // Redirect to non enterprise grade TS home page
    return redirect('/home');
  }),
  '/home': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    // const isEnterpriseGradeTrustShare = await verifyEnterpriseGrade();

    // if (isEnterpriseGradeTrustShare) {
    //   return compose(
    //     withView(
    //       <div>
    //         Enterprise Grade
    //       </div>
    //     )
    //   );
    // }

    return compose(
      withData(() => {
        const span = tracer.startSpan('home-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <FullPageLayout />
        </OnBoardingJourney>
      ),
      mount({ '/': route({ view: <Home /> }) })
    );
  }),
  '/documents': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    return compose(
      withData(() => {
        const span = tracer.startSpan('documents-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({
          view: ({ route: { data } }) => (
            <DocumentListV2
              slug={data.slug}
              title={data.title}
            />
          ),
          getData: ({ params }) => ({
            slug: params.slug,
            title: DOCUMENTS_INFO.title,
            subtitle: DOCUMENTS_INFO.subtitle,
          }),
        }),
        '/:slug': route({
          view: ({ route: { data } }) => (
            <DocumentListV2
              slug={data.slug}
              title={data.title}
            />
          ),
          getData: ({ params }) => ({
            slug: params.slug,
            title: DOCUMENTS_INFO.title,
            subtitle: DOCUMENTS_INFO.subtitle,
          }),
        }),
      })
    );
  }),
  '/notifications': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    return compose(
      withData(() => {
        const span = tracer.startSpan('notifications-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <FullPageLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({
          view: <Notifications />,
        }),
      })
    );
  }),
  '/faq': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    return compose(
      withData(() => {
        const span = tracer.startSpan('faq-page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': route({
          view: <Faq />,
        }),
      })
    );
  }),
  '*': map(async (_, context) => {
    await verifyTeamContext(context.publicTeamId);

    await AuthService.getAuthServiceInstance().isLoggedIn();

    return compose(
      withData(() => {
        const span = tracer.startSpan('page-navigation');
        span.addEvent('before-route');
        return { span };
      }),
      withView(
        <OnBoardingJourney>
          <AppLayout />
        </OnBoardingJourney>
      ),
      mount({
        '/': redirect('/home'),
        '/account': route({ view: <AccountSummary /> }),
        '/certifications': route({
          view: <CertificationsFeatureSwitcher />,
          data: { title: CERTIFICATIONS_INFO.title },
        }),
        '/certifications/:slug': route({
          view: ({ route: { data } }) => <CertificationsV2 slug={data.slug} />,
          getData: ({ params }) => ({
            slug: params.slug,
          }),
        }),
        '/certifications/:slug/:id': route({
          view: ({ route: { data } }) => (
            <CertificationStandardDetail
              slug={data.slug}
              id={data.id}
            />
          ),
          getData: ({ params }) => ({
            slug: params.slug,
            id: params.id,
          }),
        }),
        '/controls': route({
          view: <ControlDetail />,
        }),
        '/controls/:shortName': route({
          view: ({ route: { data } }) => <ControlDetail shortName={data.shortName} />,
          getData: ({ params }) => ({
            shortName: params.shortName,
          }),
        }),
        '/policies': route({
          view: <PolicyDetail />,
        }),
        '/policies/:shortName': route({
          view: ({ route: { data } }) => <PolicyDetail shortName={data.shortName} />,
          getData: ({ params }) => ({
            shortName: params.shortName,
          }),
        }),
        '/policies/:id/view': route({
          view: ({ route: { data } }) => <PolicyView id={data.id} />,
          getData: ({ params }) => ({
            id: params.id,
          }),
        }),
        '/subprocessors': route({
          view: <Subprocessors />,
          data: { title: SUBPROCESSORS_INFO.title, subtitle: SUBPROCESSORS_INFO.subtitle },
        }),
        '/subprocessors/:name': route({
          view: ({ route: { data } }) => <Subprocessors name={data.name} />,
          getData: ({ params }) => ({
            name: params.name,
            title: SUBPROCESSORS_INFO.title,
            subtitle: SUBPROCESSORS_INFO.subtitle,
          }),
        }),
      })
    );
  }),
});
